import AccordianTask from "../../../../../Components/AccordianTask/AccordianTask";
import Button from "../../../../../Components/Button/Button";
import AddContractClauseModal from "../../../../../Modals/AddContractClauseModal/AddContractClauseModal";
import { Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import css from "./TemplateTerms.module.css";
import { forwardRef, useImperativeHandle } from "react";
import DragDrop from "../../../../../Components/DragDrop/DragDrop";
import ClauseListModal from "./ClauseListModal";
import { useState } from "react";
const TemplateTerms = forwardRef(
	({ ModalAction, init, clauseList, onChange }, ref) => {
		let orderListChanged = false;
		let orderList = clauseList.reduce(
			(a, v, i) => [...a, { id: v.id, order: i }],
			[]
		);
		const [selected, setSelected] = useState(
			clauseList.filter((c) => c.selected)
		);
		useImperativeHandle(ref, () => ({
			data() {
				if (orderListChanged) {
					Post(Url.contract.saveOrderList, orderList)
						.then((resp) => {
							console.log(resp);
						})
						.catch((err) => {
							console.log(err);
						});
				}
				return {
					clauseList: clauseList
						.filter((c) => c.selected)
						.reduce((a, v) => [...a, { id: v.id }], []),
				};
			},
		}));
		const AddClause = () => {
			const Options = {
				title: "Add Clause",
				element: AddContractClauseModal,
				closeModal: (resp) => {
					ModalAction.closeModal();
					if (resp) {
						clauseList.push(resp);
						onChange && onChange(clauseList);
					}
				},
			};
			ModalAction.openModal(Options, "Add Clause");
		};
		const AddExistingClause = () => {
			const Options = {
				title: "Select Clause",
				element: ClauseListModal,
				clauseList: clauseList,
				closeModal: (resp) => {
					ModalAction.closeModal();
					if (resp) {
						setSelected(resp.filter((c) => c.selected));
					}
				},
			};
			ModalAction.openModal(Options, "Add Clause");
		};

		const handleChanged = (id, group, state) => {
			clauseList.find((c) => c.id === id).selected = state;
			setSelected(clauseList.filter((c) => c.selected));
		};
		const handleOrderChanged = (e) => {
			orderList = e.reduce((a, v, i) => [...a, { id: v.id, order: i }], []);
			orderListChanged = true;
		};

		return (
			<div className={css.TemplateTerms}>
				<p className={css.Title}>Contract Terms &amp; Conditions</p>
				<div className="justify-between-row mt-10 mb-10">
					<p className="fw-bold">Select all clauses that applies</p>
					<div className={css.ClauseBtn}>
						<Button className="Btn-Mid Inverse" onClick={AddExistingClause}>
							Add Clause
						</Button>
						<Button className="Btn-Mid Active" onClick={AddClause}>
							New Clause
						</Button>
					</div>
				</div>
				<div className={css.ClauseList}>
					{selected && (
						<DragDrop
							Element={Dragable}
							Events={{ onChange: handleChanged }}
							onOrderChanged={handleOrderChanged}
						>
							{selected}
						</DragDrop>
					)}
				</div>
			</div>
		);
	}
);

const Dragable = ({ children, Events }) => {
	const item = children;
	const { onChange } = Events;
	return (
		<AccordianTask
			key={item.id}
			selected={item.selected}
			onChange={onChange}
			id={item.id}
			title={item.name}
		>
			<p dangerouslySetInnerHTML={{ __html: item.details }}></p>
		</AccordianTask>
	);
};
export default TemplateTerms;
