const ExpensesOptions = {
	id: null,
	name: { name: "item", label: "Your Item", placeholder: "Item", value: "" },
	price: { name: "value", placeholder: "Value", value: "" },
	quantity: { name: "quality", placeholder: "Units", value: "" },
	receiptId: { name: "Image", label: "Image", value: "" },
	receiptType: { name: "Image", label: "Image", value: "" },
	receipt: { value: "", type: "image/jpeg" },
};
export default ExpensesOptions;
