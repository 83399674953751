import { useLocation } from "react-router-dom";
import { Delete } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const DeleteTemplate = () => {
	const location = useLocation();
	const state = location.state;
	const navigate = useNavigate();
	Delete(Url.deleteTemplate(state.id))
		.then(() => {
			toast("Template deleted");
			navigate("../", { state: null });
		})
		.catch((err) => {
			toast("Operation failed");
		});

	return <div>Deleting {state.name}...</div>;
};
export default DeleteTemplate;
