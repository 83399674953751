import React, { useState, useEffect } from 'react';
import back from "../../Assets/Images/Back.png";
import "./ClientMilestones.css";
import Button from "../../Components/Button/Button";
import MilestonesG from "../../Components/Milestones/MilestonesG";
import { PublicGet } from "../../Services/Server";
import Url from "../../Services/Url";
import FeedbackModal from "../../Modals/FeedbackModal/FeedbackModal";
import { useNavigate } from "react-router-dom";
import TaskService from '../../Services/TaskService';
import ViewReportWizard from "../../Modals/ViewReportModal/ViewClientReportWizard";

const CMRight = ({ ModalAction, children }) => {
  const { taskEquipmentList, title, tracking, id, jobNumber, deliveryEstimate, createdAt } = children;
  const [taskMilestones, setMilestones] = useState([]);
  const [msGroup, setGroup] = useState([]);
  const del = deliveryEstimate || createdAt;
  const delivery = new Date(del);
  const navigate = useNavigate();
  const ts = new TaskService(children);

  useEffect(() => {
    ts.setRank().then(() => {
      PublicGet(Url.taskByTracking(tracking)).then((resp) => {
        const ms = resp.taskEquipmentList.reduce((a, v) => {
          v.taskMilestones.forEach((x) => (x.equipment = v.name));
          return [...a, ...v.taskMilestones];
        }, []);
        setMilestones(ms);
      });
      setGroup(ts.msGroup());
    });
  }, [tracking, ts]);

  const closeModal = () => {
    ModalAction.closeModal();
  };

  const handleFeedback = () => {
    const options = {
      tracking,
      closeModal: ModalAction.closeModal,
      element: FeedbackModal,
      title: "Feedback",
    };
    ModalAction.openModal(options);
  };

  const openReportView = () => {
    const options = {
      element: ViewReportWizard,
      title: "Job Report",
      id,
      children,
      closeModal,
    };
    ModalAction.openModal(options);
  };

  return (
    <div className="CMRight">
      <a href="../" className="CM-Back-Arrow">
        <img src={back} alt="Back arrow" />
        <span>Go Back</span>
      </a>
      <div className="Cont-Space">
        <div>
          <h5>Service:</h5>
          <div>{title}</div>
        </div>
        <div>
          <h5>Your order number:</h5>
          <div>{jobNumber}</div>
        </div>
      </div>
      <h6>Estimated delivery:</h6>
      <h3 className="electricblue">{delivery.toDateString()}</h3>
      <div className="CM-Buttons">
        <Button onClick={openReportView} className="Btn-Small Active">See the report</Button>
        <Button onClick={handleFeedback} className="Btn-Small Inverse">Share feedback</Button>
        <Button onClick={() => navigate("notification", { state: children })} className="Btn-Small Inverse">Manage Notifications</Button>
      </div>
      <div className="CM-Milestones">
        {msGroup.length > 0 && <MilestonesG>{msGroup}</MilestonesG>}
      </div>
    </div>
  );
};

export default CMRight;
