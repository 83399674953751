import TemplatesView from "./TemplatesView";
import AddTemplate from "./AddTemplate/AddTemplate";
import SvgOptions from "../../../Components/Svg/SvgOptions";
import EditTemplate from "./EditTemplate/EditTemplate";
const TemplatesNavOptions = [
	{
		element: AddTemplate,
		path: "/add",
		access: { user: true, admin: true, super: true },
		Protected: true,
		default: true,
		icon: SvgOptions.Add,
	},
	{
		element: EditTemplate,
		path: "/Edit/:id",
		access: { user: true, admin: true, super: true },
		Protected: true,
		default: true,
		icon: SvgOptions.Add,
	},
];

const defaultRoute = {
	element: TemplatesView,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: true,
	default: true,
	icon: "SvgOptions.Dashboard",
};

export { defaultRoute };
export default TemplatesNavOptions;
