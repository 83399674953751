import React, { useState, useEffect } from "react";
import ViewReportModal from "./ViewReportModal";
import Wizard from "../../Components/Wizard/Wizard";
import WizardOption from "./WizardOption";
import Url from "../../Services/Url";
import { Get } from "../../Services/Server";

const ViewReportWizard = ({ children }) => {
	const { taskEquipmentList, vessel } = children.task;
	const pages = [WizardOption, ViewReportModal];
	const [report, setReport] = useState();
	const [logo, setLogo] = useState();

	const selected = taskEquipmentList[0];

	useEffect(() => {
		Get(Url.company.get.logo(vessel.company_id)).then((lresp) => {
			setLogo(lresp);
			if (taskEquipmentList.length === 1) {
				Get(Url.getEquipmentReport(selected.id)).then((resp) => {
					setReport(resp);
				});
			}
		});
	}, [vessel.company_id, taskEquipmentList, selected.id]);

	if (taskEquipmentList.length > 1) {
		return (
			<div>
				{logo && (
					<Wizard width={800} init={{ logo }} props={children}>
						{pages}
					</Wizard>
				)}
			</div>
		);
	}

	return (
		<div>
			{report && (
				<ViewReportModal task={children.task}>
					{{ report, selected, logo }}
				</ViewReportModal>
			)}
		</div>
	);
};

export default ViewReportWizard;
