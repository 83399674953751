import RouteView from "../../../Routes/RouteView";
import EngNavOptions, { defaultRoute } from "./EngNavOptions";
const Companies = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{EngNavOptions}
		</RouteView>
	);
};

export default Companies;
