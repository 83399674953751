import { useNavigate } from "react-router-dom";
import PageHead from "../../../Components/PageHead/PageHead";
import css from "./ContractTemplates.module.css";
import TemplateSingleList from "./TemplateSingleList/TemplateSingleList";
import { useEffect, useState } from "react";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";

const TemplatesView = ({ navoption }) => {
	const [templateList, setTemplateList] = useState([]);
	const navigate = useNavigate();
	const handleCreateTemplate = () => {
		navigate("./add");
	};
	useEffect(() => {
		Get(Url.contract.getAllTemplate).then((resp) => {
			setTemplateList(resp);
		});
	}, []);

	return (
		<div className={css.Templates}>
			<PageHead
				navoption={navoption}
				title="Contract Templates"
				addBtn={{ text: "Template", onClick: handleCreateTemplate }}
			></PageHead>
			<div className={css.TemplateList}>
				{templateList.map((item) => (
					<TemplateSingleList
						key={item.id}
						description={item.useCase}
						name={item.name}
						onClick={() => {
							navigate("./Edit/" + item.id);
						}}
						category={item.taskType.name}
					/>
				))}
			</div>
		</div>
	);
};

export default TemplatesView;
