import Button from "../../../../../Components/Button/Button";
import Page from "../../../../../Components/Page/Page";
import css from "./EditTemplate.module.css";
import MilestoneEdit from "../../../../../Components/Milestone2/MilestoneEdit";
import { useState, useEffect } from "react";
import DragDrop from "../../../../../Components/DragDrop/DragDrop";
import Svg from "../../../../../Components/Svg/Svg";
import SvgOptions from "../../../../../Components/Svg/SvgOptions";
import EditTemplateOptions from "./EditTemplateOptions";
import Form from "../../../../../Components/Form/Form";
import { Get, Post } from "../../../../../Services/Server";
import Url from "../../../../../Services/Url";
import MilestoneSelect from "../../../../../Modals/MilestoneSelect/MilestoneSelect";
import { useParams } from "react-router-dom";
import Utils from "../../../../../Services/Utills";
import User from "../../../../../Services/User";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
let lst = [];
const Left = ({ children, onChange }) => {
	const id = children ? children.id : null;
	const [fmoptions, setOptions] = useState();
	useEffect(() => {
		if (children) {
			const { name, type, use_case } = children;
			EditTemplateOptions.Fields[0][0].value = name;
			EditTemplateOptions.Fields[0][1].value = type;
			EditTemplateOptions.Fields[1][0].value = use_case;
		} else {
			EditTemplateOptions.Fields[0][0].value = null;
			EditTemplateOptions.Fields[0][1].value = null;
			EditTemplateOptions.Fields[1][0].value = null;
		}
		setOptions(EditTemplateOptions);
	}, [children]);
	const handleSave = () => {
		onChange({ ...Utils.FormObject(fmoptions), id });
	};
	return (
		<div className={css.LCont}>
			{fmoptions && <Form>{fmoptions}</Form>}
			<div className={css.Bottom}>
				<div>
					<div>
						<Button className="Btn-Mid Inverse">Cancel</Button>
					</div>
					<div>
						<Button onClick={handleSave} className="Btn-Mid Active">
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const Right = ({ children, ModalAction, onChange }) => {
	const { openPopup } = ModalAction;
	const [unassigned, setUnassigned] = useState([]);
	const [list, setList] = useState([]);
	useEffect(() => {
		Get(Url.enterprise.milestones).then((resp) => {
			//console.log(resp);
			//console.log(children.milestones)
			console.log(children);
			if (children) {
				setList(children.milestones);
				setUnassigned(
					resp.filter(
						(r) => !children.milestones.find((m) => m.name === r.name)
					)
				);
			} else {
				setUnassigned(resp);
			}
		});
	}, []);

	useEffect(() => {
		onChange(list);
		lst = [...list];
		setUnassigned(
			[...unassigned, ...list].filter((un) => !list.find((l) => l.id === un.id))
		);
	}, [list]);
	const onDelete = (ms) => {
		setList(lst.filter((fl) => fl.id !== ms.id));
		setUnassigned([...unassigned, ms]);
	};
	const addMilestone = () => {
		const Options = {
			element: MilestoneSelect,
			milestones: unassigned,
			handleAdd: handleAdd,
			handleContinue: ModalAction.closePopup,
		};
		openPopup(Options);
	};
	const handleAdd = (ms) => {
		lst.push({ ...ms });
		setList([...lst]);
	};
	return (
		<div>
			<div className={css.RHead}>
				<h2> {list.length} Milestones</h2>
			</div>
			<div className={css.RMSList}>
				<div>Milestone name</div>
				<div>Assignee</div>
				<div>Client</div>
			</div>
			<DragDrop
				Events={{ onDelete: onDelete }}
				Element={MilestoneEdit}
				onOrderChanged={setList}
			>
				{list}
			</DragDrop>
			<div onClick={addMilestone} className={css.addMilestoneBtn}>
				<div>
					<Svg className={css.svg}>{SvgOptions.Add}</Svg>
				</div>
				<div>Add Milestone</div>
			</div>
		</div>
	);
};
const EditTemplate = ({ ModalAction }) => {
	const param = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;
	const title = param["*"] + " Template";
	const [list, setList] = useState([]);
	const msChanged = (lst) => {
		setList(lst);
	};

	const tmChange = (dt) => {
		const rec = {
			...dt,
			milestones: list.reduce((a, v) => {
				delete v.id;
				return [...a, v];
			}, []),
			owner: { id: User.getBiodata().id },
		};
		Post(Url.enterprise.milestoneTemplate, rec)
			.then((resp) => {
				toast("Template Saved");
				navigate("../");
			})
			.catch((err) => {
				toast("Operation failed");
			});
	};
	return (
		<Page
			LTitle={title}
			left={<Left onChange={tmChange}>{state}</Left>}
			right={
				<Right onChange={msChanged} ModalAction={ModalAction}>
					{state}
				</Right>
			}
		></Page>
	);
};
export default EditTemplate;
