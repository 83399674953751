import "./TextField.css";
const TextField = ({ children, onChange, value }) => {
	const { className } = children;
	return (
		<input
			onChange={onChange}
			className={className}
			placeholder="Tracking number"
			type="text"
			value={value}
		></input>
	);
};
TextField.defaultProps = {
	children: {
		className: "Default-Field",
	},
	value: "",
};
export default TextField;
