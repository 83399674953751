import Svg from "../../../../Components/Svg/Svg";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import { truncateText } from "../../../../Utils/truncateText";
import css from "./TemplateSingleList.module.css";

const TemplateSingleList = ({ description, name, category, onClick }) => {
	const maxLength = 80;
	const handleClick = () => {
		onClick && onClick();
	};
	return (
		<div className={css.TemplateSingleList}>
			<p className={css.Category}>{category}</p>
			<div className={css.Row}>
				<p className={css.Name}>{name}</p>
				<Svg onClick={handleClick}>{SvgOptions.MeatballsMenu}</Svg>
			</div>
			<p className={css.Description}>{truncateText(description, maxLength)}</p>
		</div>
	);
};

export default TemplateSingleList;
