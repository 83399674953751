import style from "./Notifications.module.css";
import Notification from "./Notification";
import NotificationType, { getType } from "./NotificationType";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import { useState, useEffect } from "react";
import Pagination from "../../../Components/Pagination/Pagination";
import User from "../../../Services/User";
import { useNavigate } from "react-router-dom";
const Notifications = () => {
	const [list, setList] = useState();
	const biodata = User.getBiodata();
	const navigate = useNavigate();
	useEffect(() => {
		console.log(Url.getNotifications(biodata.id));
		Get(Url.getNotifications(biodata.id)).then((resp) => {
			setList(resp);
		});
	}, []);
	const clicked = (e) => {
		console.log(e);
	};
	const handleClick = (e) => {
		navigate("" + e.id);
	};
	return (
		<div className={style.Notifications}>
			<div className={style.Head}>
				<h2>Notification page</h2>
			</div>
			<div className={style.Cont}>
				{list &&
					list.content.map((n) => (
						<div key={n.id}>
							<Notification
								onClick={handleClick}
								type={getType(
									n.noticeViews.find((nv) => nv.user.id === biodata.id).viewed
										? 0
										: n.level
								)}
							>
								{n}
							</Notification>
						</div>
					))}
			</div>
			<div>
				{list && (
					<Pagination
						onClick={clicked}
						pageable={list.pageable}
						totalPages={list.totalPages}
					></Pagination>
				)}
			</div>
		</div>
	);
};
export default Notifications;
