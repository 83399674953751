import React from "react";
import ViewReportModal from "./ViewReportModal";
import Wizard from "../../Components/Wizard/Wizard";
import WizardOption from "./WizardOption";
import { useState, useEffect } from "react";
import Url from "../../Services/Url";
import { Get } from "../../Services/Server";
const ViewReportWizard = ({ children }) => {
	const pages = [WizardOption, ViewReportModal];
	const [report, setReport] = useState();
	const [logo, setLogo] = useState();

	const selected = children.children.taskEquipmentList[0];

	useEffect(() => {
		Get(Url.company.get.logo(children.children.vessel.company_id)).then(
			(lresp) => {
				setLogo(lresp);
				if (children.children.taskEquipmentList.length === 1) {
					Get(Url.getEquipmentReport(selected.id)).then((resp) => {
						setReport(resp);
					});
				}
			}
		);
	}, []);

	if (children.children.taskEquipmentList.length > 1) {
		return (
			<div>
				{logo && (
					<Wizard width={800} init={{ logo }} props={children}>
						{pages}
					</Wizard>
				)}
			</div>
		);
	}

	return (
		<div>
			{report && (
				<ViewReportModal task={children.children}>
					{{ report, selected, logo }}
				</ViewReportModal>
			)}
		</div>
	);
};
const Test = (props) => {
	console.log(props);
	return <div>Text</div>;
};
export default ViewReportWizard;
