import FormType from "../../../Components/Form/FormType";
import User from "../../../Services/User";
const bd = User.getBiodata();
const u = bd ? bd : {};
const AccOptions = {
	Fields: [
		[
			{
				element: FormType.Input,
				value: u.firstname,
				required: true,
				label: "First Name",
				name: "firstname",
				placeholder: "Enter firstname",
			},
			{
				element: FormType.Input,
				value: u.lastname,
				required: true,
				label: "Last Name",
				name: "lastname",
				placeholder: "Enter lastname",
			},
		],
		[
			{
				element: FormType.Input,
				value: u.phone,
				required: true,
				label: "Phone Number",
				name: "phone",
				placeholder: "Enter Phone number",
			},
			{
				element: FormType.Input,
				value: u.email,
				required: true,
				label: "Email",
				name: "email",
				placeholder: "Enter Email",
			},
		],
	],
	Buttons: [{ text: "Save", className: "Btn-Mid Active" }],
};

const SecureOptions = {
	Fields: [
		[
			{
				element: FormType.Input,
				required: true,
				label: "Current Password",
				name: "password",
				placeholder: "Current Password",
			},
			{
				element: FormType.Input,
				required: true,
				label: "New Password",
				name: "npassword",
				placeholder: "New Password",
			},
		],
	],
	Buttons: [{ text: "Save", className: "Btn-Mid Active" }],
};

export { SecureOptions };
export default AccOptions;
