import "./Login.css";
import logo from "../../Assets/Images/Logo.png";
import TextField from "../../Components/TextField/TextField";
import Button from "../../Components/Button/Button";
import Zoom from "react-reveal/Zoom";
import { useState } from "react";
import { PublicGet } from "../../Services/Server";
import Url from "../../Services/Url";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const ClientLogin = () => {
	const location = useLocation();
	const [tracking, setTracking] = useState(location.state?.tracking || "");
	const onChange = (e) => {
		setTracking(e.target.value);
	};
	const navigate = useNavigate();
	const send = () => {
		PublicGet(Url.taskByTracking(tracking))
			.then((resp) => {
				navigate("../ClientMilestones", { state: resp });
				toast("Verification Successful");
			})
			.catch(() => {
				toast("Verification failed");
			});
	};
	return (
		<div className="Login-Page">
			<div className="Login-Left">
				<div>
					<img src={logo} alt="logo" />
				</div>
				<Zoom>
					<h1 className="Login-Title">
						Track Your Order Progress in Real-Time
					</h1>
				</Zoom>
				<p className="Login-Text">
					Enter your unique order number in the input field below to monitor the
					status of your order and get real-time updates.
				</p>
				<div className="Login-Form">
					<TextField onChange={onChange} value={tracking}></TextField>
					<Button onClick={send}>Track your order</Button>
				</div>
			</div>
		</div>
	);
};
export default ClientLogin;
