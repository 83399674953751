import css from "./Details.module.css";
import ImgThumb from "../../../../Components/ImgThumb/ImgThumb";
import img from "../../../../Assets/Images/userImage.png";
import Head from "../../../../Components/Head/Head";
const Left = ({ state }) => {
	const { staffBiodata } = state;
	return (
		<div className={css.Left}>
			<Head
				title={staffBiodata.firstname + " " + staffBiodata.lastname}
				state={state}
			></Head>
			<div className={css.Main}>
				<div>
					<ImgThumb size={200}>{img}</ImgThumb>
				</div>
				<div className={css.About}>
					<h3>About</h3>
					<div>
						<div>Staff Number:</div>
						<div>{staffBiodata.staffNumber}</div>
					</div>
					<div>
						<div>First name:</div>
						<div>{staffBiodata.firstname}</div>
					</div>
					<div>
						<div>Last name:</div>
						<div>{staffBiodata.lastname}</div>
					</div>
					<div>
						<div>Role:</div>
						<div>
							{staffBiodata.appRoles
								.reduce((a, v) => [...a, v.name], [])
								.join(", ")}
						</div>
					</div>
					<div>
						<div>Email:</div>
						<div>{staffBiodata.email}</div>
					</div>
					<div>
						<div>Designation:</div>
						<div>{staffBiodata.designation?.name}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Left;
