import userImg from "../Assets/Images/userImage.png";
const UserRec = (rec) => {
	//const {staffBiodata, previousTasks}=rec;
	return [
		<div key={0}>
			<div>
				<img src={userImg} alt="userImg" />
			</div>{" "}
			<div>{rec.firstname.capitalize() + " " + rec.lastname.capitalize()}</div>
		</div>,
		rec.email,
		rec.phone,
		rec.currentYearJobs + ` task${rec.currentYearJobs > 1 ? "s" : ""} done`,
	];
};
const UserRecOp = (rec) => {
	return [
		<div key={0}>
			<div>
				<img src={userImg} alt="userImg" />
			</div>{" "}
			<div>{rec.firstname.capitalize() + " " + rec.lastname.capitalize()}</div>
		</div>,
		rec.email,
		rec.phone,
		rec.currentOpYearJobs + ` task${rec.currentOpYearJobs > 1 ? "s" : ""} done`,
	];
};
const UserRecEnt = (rec) => {
	return [
		<div key={0}>
			<div>
				<img src={userImg} alt="userImg" />
			</div>{" "}
			<div>{rec.firstname.capitalize() + " " + rec.lastname.capitalize()}</div>
		</div>,
		rec.email,
		rec.phone,
		rec.currentEntYearJobs +
			` task${rec.currentEntYearJobs > 1 ? "s" : ""} done`,
	];
};
const UserRecEntOp = (rec) => {
	return [
		<div key={0}>
			<div>
				<img src={userImg} alt="userImg" />
			</div>{" "}
			<div>{rec.firstname.capitalize() + " " + rec.lastname.capitalize()}</div>
		</div>,
		rec.email,
		rec.phone,
		rec.currentEntOpYearJobs +
			` task${rec.currentEntOpYearJobs > 1 ? "s" : ""} done`,
	];
};
const UserRecAdmin = (rec) => {
	return [
		<div key={0}>
			<div>
				<img src={userImg} alt="userImg" />
			</div>{" "}
			<div>{rec.firstname.capitalize() + " " + rec.lastname.capitalize()}</div>
		</div>,
		rec.email,
		rec.phone,
		rec.staffNumber,
	];
};
export { UserRecOp };
export { UserRecAdmin };
export { UserRecEnt };
export { UserRecEntOp };
export default UserRec;
