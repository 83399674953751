import NotificationDetails from "./NotificationDetails";
import Notifications from "./NotificationsList";
import SvgOptions from "../../../Components/Svg/SvgOptions";
const NotNavOptions = [
	{
		element: NotificationDetails,
		path: ":id",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
];
const defaultRoute = {
	element: Notifications,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Dashboard,
};
export { defaultRoute };
export default NotNavOptions;
