import "./Engineers.css";
import DataTable from "../../../Components/DataTable/DataTable";
import PageHead from "../../../Components/PageHead/PageHead";
import AddEngrModel from "../../../Modals/AddEnterpriseEngrModal/AddEngrModel";
import { Get } from "../../../Services/Server";
import { useEffect, useState } from "react";
import { UserRecEnt } from "../../../Data/UserRec";
import Role from "../../../Data/Role";
import Url from "../../../Services/Url";
import { useNavigate } from "react-router-dom";
const EngineerList = ({ navoption, ModalAction }) => {
	const navigate = useNavigate();
	const handleAddEngr = () => {
		const Options = {
			title: "Add Engineer",
			element: AddEngrModel,
			closeModal: handleCloseModal,
		};
		ModalAction.openModal(Options, "Add Engineer");
	};
	const load = () => {
		Get(Url.staffByrole(Role.enterprise)).then((resp) => {
			setStaffList({ data: resp });
		});
	};
	const handleCloseModal = () => {
		load();
		ModalAction.closeModal();
	};
	const [staffList, setStaffList] = useState({
		data: [],
	});
	useEffect(() => {
		load();
	}, []);
	const flex = [30, 30, 30, 10];
	const handleClick = (e) => {
		navigate("Details", { state: e });
	};
	const heading = ["Engineer's Name", "Email", "Phone", "Tasks"];

	return (
		<div className="Engineers">
			<PageHead
				navoption={navoption}
				addBtn={{ text: "Engineer", onClick: handleAddEngr }}
				title="Engineers"
			></PageHead>
			<div className="UserList" id="ModalRoot">
				<DataTable onClick={handleClick} flex={flex} reader={UserRecEnt}>
					{{ ...staffList, heading }}
				</DataTable>
			</div>
		</div>
	);
};
export default EngineerList;
