import RouteView from "../../../Routes/RouteView";
import NotNavOptions, { defaultRoute } from "./NotNavOptions";
const Notifications = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{NotNavOptions}
		</RouteView>
	);
};

export default Notifications;
