import { useState } from "react";
import css from "../Details/Details.module.css";
import Edit from "../Details/Edit/Edit";
import Right from "../Details/Right";
import { Post } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoPlaceholder from "../../../../Assets/Images/logoPlaceholder.png";
const AddCompany = () => {
	const [state, setState] = useState({
		name: "",
		email: "",
		phone: "",
		address: "",
		website: "",
	});
	const logo = { data: logoPlaceholder };
	const navigate = useNavigate();

	return (
		<div className={css.Details}>
			<Edit title={"New company"} logo={logo} state={state}></Edit>
		</div>
	);
};

export default AddCompany;
