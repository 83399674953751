import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../../../../Components/Button/Button";
import RoundedStatus from "../../../../Components/RoundedStatus/RoundedStatus";
import Svg from "../../../../Components/Svg/Svg";
import SvgOptions from "../../../../Components/Svg/SvgOptions";
import css from "./AddTemplate.module.css";
import TemplateDetails from "./TemplateDetails/TemplateDetails";
import TemplateReview from "./TemplateReview/TemplateReview";
import TemplateTerms from "./TemplateTerms/TemplateTerms";
import { Error, Post, Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import { toast } from "react-toastify";

const AddTemplate = ({ ModalAction, children }) => {
	const [templateStep, setTemplateStep] = useState(1);
	const [clauseList, setClauseList] = useState();
	const [init, setInit] = useState(children || {});
	const detailRef = useRef(),
		termsRef = useRef(),
		reviewRef = useRef();
	let templateStepComponent = null;
	if (templateStep === 2) {
		templateStepComponent = (
			<TemplateTerms
				ref={termsRef}
				onChange={setClauseList}
				clauseList={clauseList}
				init={init}
				ModalAction={ModalAction}
			/>
		);
	} else if (templateStep === 3) {
		templateStepComponent = (
			<TemplateReview ref={reviewRef} init={init} clauseList={clauseList} />
		);
	} else {
		templateStepComponent = (
			<TemplateDetails ref={detailRef} init={init} clauseList={clauseList} />
		);
	}
	const setSelectedClause = () => {
		const check = (clause) => {
			clause.selected =
				init.clauseList.findIndex((c) => c.id == clause.id) > -1;
		};
		if (clauseList && init.clauseList) {
			clauseList.map(check);
		}
	};
	useEffect(() => {
		Get(Url.contract.getAllClause).then((resp) => {
			setClauseList(resp);
		});
	}, []);
	useEffect(() => {
		setSelectedClause();
	}, [init, clauseList]);
	const refs = [detailRef, termsRef, reviewRef];
	const handleSave = () => {
		const index = templateStep - 1;
		const dt = { ...init, ...refs[index].current.data() };
		Post(Url.contract.saveEnterprise, dt)
			.then((resp) => {
				setInit({ ...resp });
				toast("Saved");
			})
			.catch((err) => {
				Error(err);
			});
		//    console.log(dt)
	};
	const goto = (page) => {
		const index = templateStep - 1;
		const dt = { ...init, ...refs[index].current.data() };
		setInit({ ...dt });
		setTemplateStep(page);
	};

	const sendTemplate = () => {
		console.log("send contract");
		alert("Contract Sent");
	};

	return (
		<div className={css.AddTemplate}>
			<div className={css.Top}>
				<NavLink to={"../"} style={{ textDecoration: "none" }}>
					<div className={css.BackBox}>
						<Svg>{SvgOptions.BackArrow}</Svg>
						<span>Back</span>
					</div>
				</NavLink>
				<div className={css.TopProgress}>
					<div onClick={() => goto(1)}>
						<RoundedStatus
							status={templateStep === 1 ? 2 : 1}
							onClick={() => goto(1)}
						/>
						<span>Contract Details</span>
					</div>
					<div onClick={() => goto(2)}>
						<RoundedStatus
							status={templateStep === 2 ? 2 : 1}
							onClick={() => goto(2)}
						/>
						<span>Terms</span>
					</div>
					<div onClick={() => goto(3)}>
						<RoundedStatus
							status={templateStep === 3 ? 2 : 1}
							onClick={() => goto(3)}
						/>
						<span>Review</span>
					</div>
				</div>
				<div className={css.TopButtons}>
					<Button
						className={templateStep === 3 ? "Btn-Mid" : "Btn-Mid Inverse"}
						onClick={templateStep !== 3 ? () => goto(templateStep + 1) : null}
					>
						Next
					</Button>
					<Button onClick={handleSave} className="Btn-Mid Active">
						Save
					</Button>
				</div>
			</div>
			<hr />
			{clauseList && <div className={css.Body}>{templateStepComponent}</div>}
		</div>
	);
};

export default AddTemplate;
