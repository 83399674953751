import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import css from "./Jobs.module.css";
import PageHead from "../../../Components/PageHead/PageHead";
import DataTable from "../../../Components/DataTable/DataTable";
import { Get } from "../../../Services/Server";
import Url from "../../../Services/Url";
import Time from "../../../Services/Time";

const statusText = {
	initial: "New Task",
	requirements: "Requirements",
	risk: "Risk Assessment",
	contract: "Contrat",
	null: "null",
};
const reader = (e) => {
	const statusIndex = e.status
		.split(",")
		.splice(1)
		.findIndex((s) => s < 3);
	const status = Object.keys(statusText)[statusIndex];
	const delivery = e.createdAt ? Time.getDate(e.createdAt) : "null";
	return [
		e.company,
		<div className={css[status] + " " + css.status}>{statusText[status]}</div>,
		delivery,
		"View",
	];
};

const JobReader = (rec) => {
	return [rec.companyName, rec.site, "Starlink", "View"];
};

const JobsList = ({ navoption, ModalAction }) => {
	const heading = ["Status", "Estimated Delivery", ""];
	const pendingHeading = ["Site", "Equipment", ""];
	const [data, setData] = useState([]);
	const [list, setList] = useState([]);
	const [ongoing, setOngoing] = useState();
	const [previous, setPrevious] = useState();
	const [pending, setPending] = useState();
	const [page, setPage] = useState({ pageable: null, totalPage: null });
	const navigate = useNavigate();
	const headingFlex = [20, 20, 20, 15, 15, 10];

	useEffect(() => {
		loadData(0);
	}, []);

	const loadData = (pg) => {
		loadOngoingData(pg);
		loadPreviousData(pg);
		loadPendingData(pg);
	};
	const loadOngoingData = (pg) => {
		Get(Url.sales.getAllEnterpriseInitDTO(false, pg)).then((resp) => {
			setOngoing(resp);
		});
	};
	const loadPreviousData = (pg) => {
		Get(Url.sales.getAllEnterpriseInitDTO(true, pg)).then((resp) => {
			setPrevious(resp);
			console.log("ongoing", resp.content);
		});
	};
	const loadPendingData = (pg) => {
		Get(Url.sales.getAllEnterprise(false, pg)).then((resp) => {
			setPending(resp);
			console.log("pending", resp.content);
		});
	};
	const handleAddJob = () => {
		navigate("Create");
	};
	const handleAddStarlinkJob = (e) => {
		navigate("CreateStarlink", { state: e });
	};
	const handleClick = (e) => {
		console.log(e);
		navigate(`${e.id}`, { state: e });
	};
	const handleOngoingPagination = (pg) => {
		loadOngoingData(pg);
	};
	const handlePreviousPagination = (pg) => {
		loadPreviousData(pg);
	};
	const handlePendingPagination = (pg) => {
		loadPendingData(pg);
	};

	const onSearch = (e) => {
		setData(
			list.filter((l) => l.title.toLowerCase().includes(e.toLowerCase()))
		);
	};

	return (
		<div className={css.Jobs}>
			<PageHead
				initiateBtn={{ text: "Job", onClick: handleAddJob }}
				navoption={navoption}
				onSearch={onSearch}
				hideIcon={true}
				title="Jobs"
			/>
			<div className={css.Record}>
				{pending && (
					<DataTable
						flex={headingFlex}
						handlePagination={handlePendingPagination}
						onClick={handleAddStarlinkJob}
						pageable={pending.pageable}
						reader={JobReader}
						totalPages={pending.totalPages}
					>
						{{
							heading: [
								`${pending.content?.length} Pending`,
								...pendingHeading,
							],
							data: pending.content,
						}}
					</DataTable>
				)}
			</div>

			<div className={css.Record}>
				{ongoing && (
					<DataTable
						flex={headingFlex}
						handlePagination={handleOngoingPagination}
						onClick={handleClick}
						pageable={ongoing.pageable}
						reader={reader}
						totalPages={ongoing.totalPages}
					>
						{{
							heading: [`${ongoing.content?.length} Ongoing`, ...heading],
							data: ongoing.content,
						}}
					</DataTable>
				)}
			</div>
			<div className={css.Record} style={{ marginTop: "60px" }}>
				{previous && (
					<DataTable
						flex={headingFlex}
						handlePagination={handlePreviousPagination}
						onClick={handleClick}
						pageable={previous.pageable}
						reader={reader}
						totalPages={previous.totalPages}
					>
						{{
							heading: [`${previous.content?.length} Previous`, ...heading],
							data: previous.content,
						}}
					</DataTable>
				)}
			</div>
		</div>
	);
};

export default JobsList;
