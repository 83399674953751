import css from "./AddEngineer.module.css";
import AddOperationOptions from "./AddEnterpriseOperationOptions";
import { useState } from "react";
import Form from "../Form/Form";
const AddOperation = ({ onSubmit }) => {
	const [options, setOptions] = useState(AddOperationOptions);
	const init = () => {
		const op = { ...options };
		op.Fields[0].value = "";
		setOptions(op);
	};
	const handleSubmit = () => {
		onSubmit(options);
		init();
	};
	return (
		<div className={css.AddEngineer}>
			<Form onSubmit={handleSubmit}>{options}</Form>
		</div>
	);
};

export default AddOperation;
