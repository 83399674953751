import css from "./NotificationDetails.module.css";
import Head from "../../../Components/Head/Head";
import Url from "../../../Services/Url";
import { Get } from "../../../Services/Server";
import { useState, useEffect } from "react";
import User from "../../../Services/User";
import { useParams } from "react-router-dom";
import Milestones from "../../../Components/Milestones2/Milestones";
import { setNav } from "../../../Components/NavMenu/NavMenu";
const NotificationDetails = () => {
	const [data, setData] = useState(null);
	const [taskMilestones, setMilestones] = useState([]);
	const biodata = User.getBiodata();
	const { id } = useParams();
	useEffect(() => {
		Get(Url.getNotification(id, biodata.id))
			.then((resp) => {
				console.log(resp);
				const ms = resp.task.taskEquipmentList.reduce((a, v) => {
					v.taskMilestones.map((x) => (x.equipment = v.name));
					return [...a, ...v.taskMilestones];
				}, []);
				setMilestones(ms);
				setData(resp);
				Get(Url.countNotifications(biodata.id)).then((cnt) => {
					setNav.notification(cnt);
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<div className={css.page}>
			{data && (
				<>
					<div className={css.Left}>
						<Head title={data.notification.title} dropdown={[]}></Head>
						{data.notification.details && (
							<div className={css.msg}>{data.notification.details}</div>
						)}
						<div className={css.details}>
							<div>
								<div>Notification From</div>
								<div>
									{data.notification.staff.firstname}{" "}
									{data.notification.staff.lastname}
								</div>
							</div>
							<div>
								<div>Created At</div>
								<div>{new Date(data.notification.createdAt).toUTCString()}</div>
							</div>
							<div>
								<div>From Task</div>
								<div>{data.task.title}</div>
							</div>
							<div>
								<div>Job Number</div>
								<div>{data.task.jobNumber}</div>
							</div>
							<div>
								<div>Tracking Number</div>
								<div>{data.task.tracking}</div>
							</div>
						</div>
					</div>
					<div className={css.Right}>
						<Milestones>{taskMilestones}</Milestones>
					</div>
				</>
			)}
		</div>
	);
};

export default NotificationDetails;
