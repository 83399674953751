import SvgOptions from "../../Components/Svg/SvgOptions";
import User from "../../Services/User";
import Dashboard from "./Dashboard/Dashboard";
import Tasks from "./Tasks/Tasks";
import Jobs from "./Jobs/Jobs";
import ApprovedExpenses from "../AppUser/ApprovedExpenses/ApprovedExpenses";
import Companies from "./Companies/Companies";
import ContractTemplate from "./ContractTemplate/ContractTemplate";
import Engineers from "./Engineers/Engineers";
import Expenses from "./Expenses/Expenses";
import KnowledgeBase from "../AppUser/KnowledgeBase/KnowledgeBase";
import Notifications from "./Notifications/Notifications";
import Ops from "../AppUser/Ops/Ops";
import Settings from "./Settings/Settings";

const btns = [
	{
		id: 1,
		text: "",
		icon: SvgOptions.Settings,
		className: "Inverse",
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 2,
		text: "Add Task",
		icon: SvgOptions.Add,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 3,
		text: "Add Engineer",
		icon: SvgOptions.Add,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
	{
		id: 4,
		text: "View Report",
		icon: SvgOptions.Receipt,
		onClick: () => {
			alert("btnAdd Clicked");
		},
	},
];

const pageControls = new Map([
	[
		"admin",
		new Map([
			["task", [btns[1], btns[0], btns[3]]],
			["engineer", [btns[2], btns[0]]],
		]),
	],
	[
		"operation",
		new Map([
			["task", [btns[1], btns[0]]],
			["engineer", [btns[2]]],
		]),
	],
	[
		"enterprise",
		new Map([
			["task", [btns[0]]],
			["engineer", []],
		]),
	],
]);

const getControls = (page) => {
	const app_role = User.getRole();
	if (!app_role) return;
	if (pageControls.has(app_role.name)) {
		const app_ctrls = pageControls.get(app_role.name);
		return app_ctrls.get(page);
	}
};
const EpNavOption = [
	{
		element: Dashboard,
		path: "EnterpriseDashboard",
		access: ["enterprise", "admin", "account", "enterprise-ops", "enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		title: "Dashboard",
		icon: SvgOptions.Dashboard,
	},
	{
		element: Tasks,
		path: "Tasks/*",
		access: ["enterprise", "admin", "enterprise-ops", "enterprise-admin"],
		Protected: false,
		default: false,
		category: "enterprise",
		icon: SvgOptions.Task,
		controls: () => getControls("task"),
	},
	{
		element: Jobs,
		path: "Jobs/*",
		access: ["enterprise-bdm", "enterprise-admin","admin"],
		Protected: false,
		default: false,
		category: "enterprise",
		icon: SvgOptions.Task,
	},
	{
		element: ApprovedExpenses,
		path: "Expense List/*",
		access: ["account", "admin","enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
	{
		element: Engineers,
		path: "Engineers/*",
		access: ["engineer", "enterprise", "admin", "account", "enterprise-ops", "enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		icon: SvgOptions.Engineer,
	},
	{
		element: Ops,
		path: "Ops/*",
		access: ["engineer", "enterprise", "admin", "account", "enterprise-ops", "enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		icon: SvgOptions.Ops,
	},
	{
		element: Companies,
		path: "Companies/*",
		access: ["engineer", "enterprise", "admin", "account", "enterprise-ops", "enterprise-admin"],
		Protected: false,
		default: true,
		category: "enterprise",
		icon: SvgOptions.Moving,
	},
	{
		element: Expenses,
		path: "Expenses/*",
		access: ["head"],
		Protected: false,
		default: true,
		category: "enterprise",
		title: "Expenses",
		icon: SvgOptions.Receipt,
	},
	{
		element: ContractTemplate,
		path: "contractTemplates/*",
		access: ["admin", "enterprise-admin"],
		Protected: true,
		default: true,
		title: "Contract Templates",
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
	{
		element: KnowledgeBase,
		path: "KnowledgeBase/*",
		access: ["engineer", "admin", "enterprise", "enterprise-admin"],
		Protected: false,
		default: true,
		title: "Knowledge Base",
		category: "enterprise",
		icon: SvgOptions.Receipt,
	},
];

const accNavOptions = [
	{
		element: Notifications,
		path: "Notifications/*",
		access: [
			"enterprise",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise",
			"enterprise-bdm",
			"enterprise-ops",
			"enterprise-admin"
		],
		Protected: false,
		default: true,
		notify: 3,
		icon: SvgOptions.Bell,
	},
	{
		element: Settings,
		path: "Settings",
		access: [
			"enterprise",
			"admin",
			"account",
			"operation",
			"bdm",
			"enterprise",
			"enterprise-bdm",
			"enterprise-ops",
			"enterprise-admin"
		],
		Protected: false,
		default: true,
		icon: SvgOptions.Settings,
	},
];
const logoutNav = {
	element: Dashboard,
	path: "Logout",
	access: [
		"enterprise",
		"admin",
		"account",
		"operation",
		"bdm",
		"enterprise",
		"enterprise-bdm",
		"enterprise-ops",
		"enterprise-admin"
	],
	Protected: false,
	default: true,
	title: "",
	subTitle: "Log out",
	navImg: "",
	confirm: {},
};
export { accNavOptions, logoutNav };
export default EpNavOption;
