import RouteView from "../../../Routes/RouteView";
import ExpensesNavOptions, { defaultRoute } from "./ExpensesNavOptions.js";
const ApprovedExpenses = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{ExpensesNavOptions}
		</RouteView>
	);
};

export default ApprovedExpenses;
