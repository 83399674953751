import RouteView from "../../../../Routes/RouteView";
import mtNavOption, { defaultRoute } from "./mtNavOption";
const MilestoneRoute = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{mtNavOption}
		</RouteView>
	);
};
export default MilestoneRoute;
