import Head from "../../../../Components/Head/Head";
import css from "./Details.module.css";
const Left = ({ state, logo }) => {
	return (
		<div className={css.Left}>
			<Head state={state} title={state.name}></Head>
			<div className={css.Main}>
				<div className={css.logo}>
					<img src={logo.data}></img>
				</div>
				<div className={css.Info}>
					<div>
						<div>Email</div>
						<div>{state.email}</div>
					</div>
					<div>
						<div>Website</div>
						<div>{state.website}</div>
					</div>
					<div>
						<div>Contact Phone</div>
						<div>{state.phone}</div>
					</div>
					<div>
						<div>Address</div>
						<div>{state.address}</div>
					</div>
					<div>
						<div>Created At</div>
						<div>{state.created_at}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Left;
