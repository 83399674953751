import FormType from "../../../../../Components/Form/FormType";
const FormOptions = {
	Fields: [
		{
			element: FormType.Input,
			required: true,
			label: "Company Name",
			name: "name",
			placeholder: "Enter Company Name",
		},
		{
			element: FormType.Input,
			required: true,
			label: "Email",
			name: "email",
			placeholder: "Enter Email",
		},
		{
			element: FormType.Input,
			label: "Phone Number",
			name: "phone",
			placeholder: "Enter Phone number",
		},
		{
			element: FormType.Input,
			label: "Address",
			name: "address",
			placeholder: "Enter Address",
		},
		{
			element: FormType.Input,
			label: "Website",
			name: "website",
			placeholder: "Enter Website",
		},
	],
	Buttons: [{ text: "Save", className: "Btn-Mid Active" }],
};
export default FormOptions;
