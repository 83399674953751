import DropDown from "../../../../Components/DropDown/DropDown";
import style from "./Template.module.css";
const Template = ({ children }) => {
	const { name, type, use_case } = children;
	const handleMenu = () => {
		alert("clicked");
	};
	return (
		<div className={style.Template}>
			<div className={style.Top}>
				<div>
					<div className={style.type}>{type && type.name}</div>
					<div className={style.name}>{name}</div>
				</div>
				<div className={style.dropdown}>
					<DropDown state={children} onClick={handleMenu}></DropDown>
				</div>
			</div>

			<div className={style.content}>{use_case}</div>
		</div>
	);
};
export default Template;
