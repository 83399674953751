import css from "./TemplateReview.module.css";
import { forwardRef, useImperativeHandle } from "react";

const TemplateReview = forwardRef(({ init, clauseList }, ref) => {
	const clauses = clauseList.filter((c) => c.selected);
	useImperativeHandle(ref, () => ({
		data() {
			return {};
		},
	}));
	return (
		<div className={css.Review}>
			<p className={css.Title}>Contract Review</p>
			<div>
				<p className={css.SubTitle}>Service Provider Information</p>
				<div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Company: </p>
							<p>Fieldbase Services Limited</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Email: </p>
							<p>sales@fbase.co.uk</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Company Number: </p>
							<p>F10637893</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Phone: </p>
							<p>07425-155-321</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Vat Number: </p>
							<p>313 7483 09</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>County: </p>
							<p>United Kingdom</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Contact First Name: </p>
							<p>Chinedu</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Province: </p>
							<p>Greater London</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Contact Last Name: </p>
							<p>Abanobi</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>City: </p>
							<p>London</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Role: </p>
							<p>Managing Director</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Postal Code: </p>
							<p>EC3V 3NG</p>
						</div>
					</div>
					<div className={css.InfoRow}>
						<div className={css.Items}>
							<p className={css.Key}>Also Designated as: </p>
							<p>-</p>
						</div>
						<div className={css.Items}>
							<p className={css.Key}>Address: </p>
							<p>International House, 36-38 Cornhill</p>
						</div>
					</div>
				</div>
			</div>
			<div>
				<p className={css.SubTitle}>Terms &amp; Condition</p>
				<div>
					<ol>
						{clauses.map((clause) => (
							<li key={clause.id} className={css.ListTitles}>
								<div>{clause.name}</div>
								<p
									className={css.Details}
									dangerouslySetInnerHTML={{ __html: clause.details }}
								></p>
							</li>
						))}
					</ol>
				</div>
			</div>
			<div>
				<p className={css.SubTitle}>Signature</p>
				<div className={css.InfoRow}>
					<div>
						<p className={css.SignatureNames}>Chinedu Abanobi</p>
						<p>Managing Director at Fieldbase</p>
					</div>
					<div>
						<p className={css.SignatureNames}>Contact Name</p>
						<p>Contact Designation</p>
					</div>
				</div>
				<img src="/signature.png" alt="signature" />
				<p className={css.Date}>Date: 07 Jun 2024</p>
			</div>
		</div>
	);
});

export default TemplateReview;
