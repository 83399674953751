import { useNavigate } from "react-router-dom";
import User from "../../Services/User";

const NavToggle = ({ selectedCategory, setSelectedCategory }) => {
	const navigate = useNavigate();
	const base = User.getBase();

	const handleCategoryChange = (e) => {
		const newCategory = e.target.value;
		setSelectedCategory(newCategory);

		if (newCategory === "maritime") {
			navigate(`${base}`);
		} else if (newCategory === "enterprise") {
			navigate(`${base}EnterpriseDashboard`);
		}
	};

	return (
		<div>
			<select
				value={selectedCategory}
				onChange={handleCategoryChange}
				className="NavTitle dropdown"
			>
				<option value="maritime">Maritime</option>
				<option value="enterprise">Enterprise</option>
			</select>
		</div>
	);
};

export default NavToggle;
