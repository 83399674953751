import RouteView from "../../../Routes/RouteView";
import ExpensesNavOptions, { defaultRoute } from "./ExpensesNavOptions";
const Expenses = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{ExpensesNavOptions}
		</RouteView>
	);
};

export default Expenses;
