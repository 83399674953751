import RouteView from "../../../Routes/RouteView";
import CompanyNavOption, { defaultRoute } from "./CompanyNavOption";
const Companies = ({ navoption, ModalAction }) => {
	return (
		<RouteView
			navoption={navoption}
			ModalAction={ModalAction}
			defaultRoute={defaultRoute}
		>
			{CompanyNavOption}
		</RouteView>
	);
};

export default Companies;
