import SvgOptions from "../../../Components/Svg/SvgOptions";
import Details from "./Details/Details";
import EngineerList from "./EngineerList";
const EngNavOptions = [
	{
		element: Details,
		path: "Details/*",
		access: { user: true, admin: true, super: true },
		Protected: false,
		default: true,
		icon: SvgOptions.Dashboard,
	},
];
const defaultRoute = {
	element: EngineerList,
	path: "*",
	access: { user: true, admin: true, super: true },
	Protected: false,
	default: true,
	icon: SvgOptions.Dashboard,
};
export { defaultRoute };
export default EngNavOptions;
