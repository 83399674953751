import React from "react";
import css from "./style.module.css";
import Input from "../../Components/Form/Input/Input";
import Button from "../../Components/Button/Button";
import Url from "../../Services/Url";
import { Post } from "../../Services/Server";
import { toast } from "react-toastify";
const ExpenseRemark = ({ children }) => {
	console.log(children);
	const { status, expenses, amount, approvedBy, paidBy, closeModal } = children;
	const approval = {
		remark: "",
		approvedBy,
		status,
	};
	const payment = {
		remark: "",
		paidBy,
		amount,
		status,
		currency: 1,
	};
	const remark = { label: "Enter Details" };
	const save = () => {
		let endpoint = Url.expenses.saveEnterprise;
		if (approvedBy) {
			endpoint = Url.expenses.saveApproval;
			approval.remark = remark.value;
			expenses.expenseApprovals = approval;
		} else if (paidBy) {
			endpoint = Url.expenses.savePayment;
			payment.remark = remark.value;
			expenses.expenseApprovals.payment = payment;
		}
		console.log(expenses);
		Post(endpoint, expenses)
			.then((resp) => {
				toast("Approval status saved");
				closeModal();
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<div className={css.Modal}>
			<Input>{remark}</Input>
			<div className={css.Controls}>
				<div>
					<Button onClick={save} className="Active Btn-Mid">
						Save
					</Button>
				</div>
				<div>
					<Button className="Inverse Btn-Mid">Close</Button>
				</div>
			</div>
		</div>
	);
};

export default ExpenseRemark;
