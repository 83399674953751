import css from "./Settings.module.css";
import userImg from "../../../Assets/Images/userImage2.png";
import ImgThumb from "../../../Components/ImgThumb/ImgThumb";
import Button from "../../../Components/Button/Button";
import Form from "../../../Components/Form/Form";
import AccOptions, { SecureOptions } from "./Options";
const Settings = () => {
	return (
		<div className={css.Settings}>
			<div className={css.Head}>
				<div>
					<ImgThumb size={120}>{userImg}</ImgThumb>
				</div>
				<div>
					<h2>User Profile</h2>
					<div>
						You can upload images up to 256x256. Your avatar shows up in your
						public profile and your team notifications.
					</div>
					<div className={css.Btn}>
						<Button className="Btn-Mid Active">Upload</Button>
					</div>
				</div>
			</div>

			<div>
				<h2 className={css.Title}>Account Details</h2>
				<div>
					<Form>{AccOptions}</Form>
				</div>
			</div>

			<div>
				<h2 className={css.Title}>Account Security</h2>
				<div>
					<Form>{SecureOptions}</Form>
				</div>
			</div>
		</div>
	);
};
export default Settings;
