import css from "./Details.module.css";
import Right from "./Right";
import { useLocation, Navigate } from "react-router-dom";
import RouteView from "../../../../Routes/RouteView";
import { ModalAction } from "../../../../Routes/NavRouter";
import NavOptions, { defaultRoute } from "./NavOptions";
import { Get } from "../../../../Services/Server";
import Url from "../../../../Services/Url";
import { useState, useEffect } from "react";
const Details = ({ navoption }) => {
	const state = useLocation().state;
	const [data, setData] = useState();
	useEffect(() => {
		if (state) {
			Get(Url.enterprise.userTasks(state.id)).then((resp) => {
				setData(resp);
			});
		}
	}, []);

	if (!state) {
		return <Navigate to={"../"} />;
	}

	return (
		<div className={css.Details}>
			{data && (
				<>
					<RouteView
						defaultRoute={defaultRoute}
						props={{ state: data }}
						ModalAction={ModalAction}
						navoption={navoption}
					>
						{NavOptions}
					</RouteView>
					<Right state={data}></Right>
				</>
			)}
		</div>
	);
};

export default Details;
