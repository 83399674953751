import Form from "../Form/Form"
import css from "./AddEquipment.module.css"
import AddEquipmentOptions from "./AddEquipmentOptions"
import Url from "../../Services/Url"
import { Get } from "../../Services/Server"
import User from "../../Services/User"
import { useState, useEffect } from "react"
const AddEquipment = ({onSubmit, children, edit=false}) => {
  const [options, setOptions]=useState(AddEquipmentOptions)
  const init=()=>{
    const op={...options}
    op.Fields[0].value="";
    op.Fields[1][0].value="";
    op.Fields[1][1].value="";
    setOptions(op);
  }
  if(edit){
    const op={...options}
    op.Fields[0].value=children?.equipment;
    op.Fields[1][0].value=children?.type;
    op.Fields[1][1].value=children?.template;
  }

    const taskChange=(e)=>{
    if(typeof(e)==="object" && e!=null){
        Get(Url.enterprise.taskTemplates(User.getBiodata().id, e.id)).then(resp=>{
        const op={...options}
        op.Fields[1][1].options=resp;
        setOptions(op);    
        }).catch(err=>{
            console.log(err)
        })
    }
}
const handleSubmit=()=>{
  if(edit){
    options.index=children?.index
  }
  onSubmit&&onSubmit(options)
  init();
}
options.Fields[1][0].onSelected=taskChange;

  return (
    <div className={css.AddEquipment}>
        {options&&
          <Form onSubmit={handleSubmit}>{options}</Form>
        }
    </div>
  )
}

export default AddEquipment